export type TooltipConfigs = {
    icon: { name: string; size: string };
    translateKeyText: string;
};

export const ZX_FORMS_TOOLTIPS_CONFIGS: { [key: string]: TooltipConfigs } = {
    REQUIRED: { icon: { name: "asterisk", size: "xs" }, translateKeyText: "REQUIRED_FIELD" },
    RESTART: { icon: { name: "redo", size: "sm" }, translateKeyText: "RESTART_REQUIRED" },
    CHANNEL_NAME_LOCKED: { icon: { name: "lock", size: "sm" }, translateKeyText: "TOOLTIP.CHANNEL_NAME_LOCK" },
    TAGS_INFO: { icon: { name: "info-circle", size: "sm" }, translateKeyText: "TOOLTIP.TAGS" }
};
